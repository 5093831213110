<template>
  <div>
    <Payments
      ref="betPayments"
      :game="typeform"
      :bet-payments="payments"
      @savePayments="savePayments"
    />
    <form @submit.prevent="save()">
      <b-row>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Mesa Fisica</label>
            <div>
              <select
                v-model="typeform.providerId"
                name="providerId"
                class="form-select border-0 shadow-lg"
                :class="{
                  'is-invalid': typesubmit && $v.typeform.providerId.$error,
                }"
                placeholder="Seleccione.."
                ref="rouletteFisicSelect"
              >
                <option disabled selected>{{ $t('helpers.select') }}...</option>
                <option
                  v-for="(item, index) in gameFisic"
                  :key="index"
                  :value="item"
                  :selected="typeform.providerId == item"
                >
                  {{ item }}
                </option>
              </select>
              <div
                v-if="typesubmit && $v.typeform.providerId.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.typeform.providerId.required">
                  Este campo es requerido.
                </span>
              </div>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Nombre</label>
            <vs-input
              v-model="typeform.name"
              placeholder="Nombre"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.name.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.name.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.name.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Logo</label>
            <vs-input
              v-model="typeform.logo"
              placeholder="Logo"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.logo.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.logo.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.logo.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Imagen de fondo</label>
            <vs-input
              v-model="typeform.imgBackground"
              placeholder="url background"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.imgBackground.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.imgBackground.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.imgBackground.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Color de fondo</label>
            <vs-input
              v-model="typeform.colorBackground"
              placeholder="color background"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.colorBackground.$error,
              }"
              type="color"
            />
            <div
              v-if="typesubmit && $v.typeform.colorBackground.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.colorBackground.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>URL de transmision</label>
            <vs-input
              v-model="typeform.urlTransmision"
              placeholder="nano cosmos"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.urlTransmision.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.urlTransmision.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.urlTransmision.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>URL para Launch</label>
            <vs-input
              v-model="typeform.launchURL"
              placeholder="https://front.com"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.launchURL.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.launchURL.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.launchURL.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>URL para volver al lobby</label>
            <vs-input
              v-model="typeform.lobby"
              placeholder="https://api.com"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.lobby.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.lobby.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.lobby.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Tiempo de apuesta</label>
            <vs-input
              v-model="typeform.betTime"
              placeholder="color background"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.betTime.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.betTime.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.betTime.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Time 1</label>
            <vs-input
              v-model="typeform.timeOne"
              placeholder="TIME 1 CAMERA"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.timeOne.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.timeOne.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.timeOne.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Time 2</label>
            <vs-input
              v-model="typeform.timeTwo"
              placeholder="TIME 2 CAMERA"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.timeTwo.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.timeTwo.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.timeTwo.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Time 3</label>
            <vs-input
              v-model="typeform.timeThree"
              placeholder="TIME 3 CAMERA"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.timeThree.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.timeThree.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.timeThree.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Tiempo adicional</label>
            <vs-input
              v-model="typeform.aditionalTime"
              placeholder="TIEMPO ADICIONAL"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.aditionalTime.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.aditionalTime.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.aditionalTime.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Tiempo para iniciar ronda</label>
            <vs-input
              v-model="typeform.startRoundTime"
              placeholder="TIEMPO PARA INICIAR RONDA"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.startRoundTime.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.startRoundTime.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.startRoundTime.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <label>BANCO DE JACKPOTS</label>
          <vs-input
            v-model="typeform.bank"
            placeholder="1000"
            type="number"
            name="jackpotBank"
            step="any"
            class="shadow-lg"
          />
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label> MAX. APUESTAS POR SIMBOLOS: </label>
            <vs-input
              v-model="typeform.maxBetFigures"
              placeholder="Ej: 6"
              border
              class="shadow-lg"
            />
          </div>
        </b-col>
        <b-col sm="12" md="12">
          <vs-button type="button" @click="modifyPayments"> PAGOS </vs-button>
        </b-col>
      </b-row>
      <div class="mb-3 mt-3">
        <div class="d-flex">
          <vs-button>Guardar</vs-button>
          <vs-button @click="$emit('closeModa')" success type="button">
            Cancelar
          </vs-button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import Payments from './Payments.vue';

export default {
  props: {
    typeform: {
      type: Object,
      default: () => ({
        name: '',
        logo: '',
        providerId: 'W1',
        betPays: [],
      }),
    },
    gameFisic: {
      type: Array,
      default: () => {
        return ['W1', 'W2'];
      },
    },
  },
  validations: {
    typeform: {
      name: {
        required,
      },
      providerId: {
        required,
      },
      logo: {
        required,
      },
      imgBackground: {
        required,
      },
      colorBackground: {
        required,
      },
      urlTransmision: {
        required,
      },
      betTime: {
        required,
      },
      launchURL: {
        required,
      },
      lobby: {
        required,
      },
      timeOne: {
        required,
      },
      timeTwo: {
        required,
      },
      aditionalTime: {
        required,
      },
      startRoundTime: {
        required,
      },
    },
  },
  data() {
    return {
      typesubmit: false,
      payments: [],
    };
  },
  computed: {
    ...mapGetters({
      success: 'wheel/getSuccess',
    }),
  },
  methods: {
    async save() {
      try {
        this.typesubmit = true;
        // stop here if form is invalid
        console.log(this.typeform);
        this.$v.$touch();
        if (this.$v.$invalid) return console.log('error');
        if (this.typeform._id) return this.updateWheel(this.typeform);
        this.createWheel(this.typeform);
      } catch (error) {
        console.log('ERROR SAVE WHEEL', error);
      }
    },
    async updateWheel(doc) {
      try {
        await this.update(doc);
        this.$emit('closeModa');
      } catch (error) {
        throw new Error(error);
      }
    },
    async createWheel(doc) {
      try {
        await this.create(doc);
        if (!this.success) throw new Error('Error creando el juego');
        this.$swal.fire({
          title: 'Creado',
          icon: 'success',
        });
        this.$emit('closeModa');
      } catch (error) {
        this.$swal.fire({
          title: 'Error creando el juego',
          icon: 'error',
        });
        console.log(error);
      }
    },
    modifyPayments() {
      const { betPays } = this.typeform;
      const bets = betPays || [];
      this.payments = [...bets];
      this.$refs.betPayments.modal = true;
    },
    savePayments() {
      this.typeform.betPays = [...this.payments];
      this.save();
    },
    ...mapActions({
      create: 'wheel/create',
      update: 'wheel/update',
    }),
  },
  components: { Payments },
};
</script>
