<template>
  <b-modal id="modal-alert-emails" v-model="modal" size="lg" hide-footer>
    <template #modal-title>{{
      Object.entries(game).length ? game.name : 'Juego'
    }}</template>
    <div class="my-4">
      <div
        v-for="(item, i) in betPayments"
        :key="i"
        class="d-flex justify-content-between align-items-center"
      >
        <div>
          Numero: <strong>{{ item.number }}</strong>
        </div>
        <div>
          Pago: <strong>{{ item.multiplier }}</strong>
        </div>
        <vs-button type="button" danger @click="() => betPayments.splice(i, 1)"
          >Eliminar</vs-button
        >
      </div>
      <div class="row align-items-center mt-5">
        <vs-input
          border
          primary
          type="number"
          v-model="number"
          placeholder="Number"
          class="col-4"
        />
        <vs-input
          border
          primary
          type="number"
          v-model="multiplier"
          placeholder="Pago"
          class="col-4"
        />
        <vs-button
          type="button"
          primary
          @click="() => betPayments.push({ number, multiplier })"
          class="col-3"
          >Añadir</vs-button
        >
      </div>
      <div class="d-flex justify-content-center">
        <vs-button type="button" @click="savePayments">Guardar</vs-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    betPayments: {
      type: Array,
      default: () => [{ number: 1, payment: 2 }],
    },
    game: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      modal: false,
      paymentToAdd: {},
      number: 0,
      multiplier: 0,
    };
  },
  methods: {
    savePayments() {
      this.$emit('savePayments');
    },
  },
};
</script>
